import './App.scss'
import Container from 'react-bootstrap/container'
import Row from 'react-bootstrap/row'
import Col from 'react-bootstrap/col'
import Form from 'react-bootstrap/form'
import Button from 'react-bootstrap/button'
import { useState } from 'react'
import { Card, InputGroup } from 'react-bootstrap'

function App() {
  const [formData, setFormData] = useState({
    st1p1: '', // Statement 1 - All or some (Symbol Tender)
    st1p2: '', // Statement 1 - Subject
    st1p3: '', // Statement 1 - Connective Verb (Are or Are not)
    st1p4: '', // Statement 1 - Accused
    st2p1: '', // Statement 2 - All or some (Symbol Tender)
    st2p2: '', // Statement 2 - Subject
    st2p3: '', // Statement 2 - Connective Verb (Are or Are not)
    st2p4: '', // Statement 2 - Accused
  })
  const [conclusion, setConclusion] = useState([])

  const onSubmitForm = (e) => {
    e.preventDefault()
    const conclusion = generateConclusion()
    setConclusion(conclusion)
    return false
  }

  const getType = (p1, p2) => {
    // p1 - Symbol Tender
    // p2 - Connective Verb
    let result = ''
    if (p1 && p2) {
      if (p1 === 'all') {
        // Universal Affirmative (A)
        // Universal Adverbial (I)
        return p2 === 'are' ? 'A' : 'I'
      } else {
        // Partial Affirmative (E)
        // Partial Answer (O)
        return p2 === 'are' ? 'E' : 'O'
      }
    }
    return result
  }

  const generateConclusion = () => {
    const statement1Type = getType(formData.st1p1, formData.st1p3)
    const statement2Type = getType(formData.st2p1, formData.st2p3)

    console.log(statement1Type, statement2Type)

    if (statement1Type === 'A') {
      // Logic for generating the conclusion when both statements are Universal Affirmative
      if (statement2Type === 'A') {
        if (formData.st1p4.toLowerCase() === formData.st2p2.toLowerCase()) {
          return [`all ${formData.st1p2} are ${formData.st2p4}`]
        }
        if (formData.st1p2.toLowerCase() === formData.st2p4.toLowerCase()) {
          return [`all ${formData.st2p2} are ${formData.st1p4}`]
        }
      }

      if (statement2Type === 'I') {
        if (formData.st1p4.toLowerCase() === formData.st2p2.toLowerCase()) {
          return [`all ${formData.st1p2} are not ${formData.st2p4}.`]
        }
      }
    }

    // No valid conditionals found
    return ['Unable to generate a conclusion.']
  }

  return (
    <div className="d-flex align-items-center justify-content-center vw-100 vh-100">
      <Container>
        <h1 className="text-center my-5">Demo</h1>
        <Form onSubmit={onSubmitForm}>
          <Row className="align-items-center mb-3">
            <Col>
              <Form.Group>
                <InputGroup>
                  <InputGroup.Text>
                    {getType(formData.st1p1, formData.st1p3)}
                  </InputGroup.Text>
                  <Form.Select
                    value={formData.st1p1}
                    onChange={(e) =>
                      setFormData({ ...formData, st1p1: e.target.value })
                    }
                    required
                  >
                    <option value="">Select option</option>
                    <option value="all">All</option>
                    <option value="some">Some</option>
                  </Form.Select>
                </InputGroup>
              </Form.Group>
            </Col>
            <Col>
              <Form.Group>
                <Form.Control
                  type="text"
                  required
                  value={formData.st1p2}
                  onChange={(e) =>
                    setFormData({ ...formData, st1p2: e.target.value })
                  }
                />
              </Form.Group>
            </Col>
            <Col>
              <Form.Group>
                <Form.Select
                  value={formData.st1p3}
                  onChange={(e) =>
                    setFormData({ ...formData, st1p3: e.target.value })
                  }
                >
                  <option value="">Select option</option>
                  <option value="are">Are</option>
                  <option value="are not">Are not</option>
                </Form.Select>
              </Form.Group>
            </Col>
            <Col>
              <Form.Group>
                <Form.Control
                  value={formData.st1p4}
                  type="text"
                  required
                  onChange={(e) =>
                    setFormData({ ...formData, st1p4: e.target.value })
                  }
                />
              </Form.Group>
            </Col>
          </Row>
          <Row className="align-items-center mb-3">
            <Col>
              <Form.Group>
                <InputGroup>
                  <InputGroup.Text>
                    {getType(formData.st2p1, formData.st2p3)}
                  </InputGroup.Text>
                  <Form.Select
                    value={formData.st2p1}
                    onChange={(e) =>
                      setFormData({ ...formData, st2p1: e.target.value })
                    }
                    required
                  >
                    <option value="">Select option</option>
                    <option value="all">All</option>
                    <option value="some">Some</option>
                  </Form.Select>
                </InputGroup>
              </Form.Group>
            </Col>
            <Col>
              <Form.Group>
                <Form.Control
                  type="text"
                  required
                  value={formData.st2p2}
                  onChange={(e) =>
                    setFormData({ ...formData, st2p2: e.target.value })
                  }
                />
              </Form.Group>
            </Col>
            <Col>
              <Form.Group>
                <Form.Select
                  value={formData.st2p3}
                  onChange={(e) =>
                    setFormData({ ...formData, st2p3: e.target.value })
                  }
                >
                  <option value="">Select option</option>
                  <option value="are">Are</option>
                  <option value="are not">Are not</option>
                </Form.Select>
              </Form.Group>
            </Col>
            <Col>
              <Form.Group>
                <Form.Control
                  value={formData.st2p4}
                  type="text"
                  required
                  onChange={(e) =>
                    setFormData({ ...formData, st2p4: e.target.value })
                  }
                />
              </Form.Group>
            </Col>
          </Row>

          <div className="text-center">
            <Button variant="primary" type="submit">
              Conclusion
            </Button>
          </div>
        </Form>
        <Card className="mt-5">
          <Card.Header>Conclusion</Card.Header>
          <Card.Body>
            <ul className="list-unstyled text-capitalize">
              <li>
                Statement 1:{' '}
                {`${formData.st1p1} ${formData.st1p2} ${formData.st1p3} ${formData.st1p4} `}
              </li>
              <li>
                Statement 2:{' '}
                {`${formData.st2p1} ${formData.st2p2} ${formData.st2p3} ${formData.st2p4} `}
              </li>
              {conclusion.map((c) => (
                <li>
                  Conclusion: <b>{c}</b>
                </li>
              ))}
            </ul>
          </Card.Body>
        </Card>
      </Container>
    </div>
  )
}

export default App
